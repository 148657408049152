<template>
    <div class="container">
        <b-row>
            <b-col sm="6" v-for="(logo, type, index) in logos" :key="type">
                <b-card no-body class="card-marketing-image bg-white animated-fast fadeInUp" :class="{'open': logo.open}" :style="{'animation-delay': (0.1*index) + 's'}">
                    <div class="text-center position-relative" style="height: 300px">
                            <b-card-img :src="getPath(logo.name, 'png')" class="p-3 mw-100" :style="{maxHeight: (logo.height ? logo.height: 300) + 'px'}"></b-card-img>
                    </div>
                    <b-card-body class="p-0 bg-primary">
                        <a @click.prevent="toggle(type)" href="" class="p-3 d-block text-secondary text-decoration-none"><i class="far fa-image mr-3"></i>
                            {{logo.title}}<i class="fad fa-arrow-up float-right"></i>
                        </a>

                        <!--clipboard copies -->
                        <div class="px-3 pb-3">
                            <div class="small mb-1">{{ logo.use_case }}</div>

                            <span class="small">Forum (BBCode)</span>
                            <b-input-group @click="copy(getEmbedCode(getPath(logo.name, 'png'), 'bbcode'))">
                                <b-input class="bg-transparent b" :value="getEmbedCode(getPath(logo.name, 'png'), 'bbcode')" readonly></b-input>
                                <b-input-group-append class="b">
                                    <b-button variant="primary" class="text-secondary"><i class="far fa-clipboard"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <span class="small">HTML</span>
                            <b-input-group @click="copy(getEmbedCode(getPath(logo.name, 'png'), 'html'))">
                                <b-input class="bg-transparent b" :value="getEmbedCode(getPath(logo.name, 'png'), 'html')" readonly></b-input>
                                <b-input-group-append class="b">
                                    <b-button variant="primary" class="text-secondary"><i class="far fa-clipboard"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>

                            <span class="small mt-3 d-block">Files for media creation</span>
                            <b-btn-toolbar class="mt-1">
                                <b-btn variant="primary" :href="getPath(logo.name, 'png')" target="_blank" class="mr-3 b">PNG</b-btn>
                                <b-btn variant="primary" :href="getPath(logo.name, 'svg')" target="_blank" class="mr-3 b">SVG</b-btn>
                            </b-btn-toolbar>
                        </div>

                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

    </div>
</template>
<script>

    export default {
        components: {},
        data() {
            return {
                download_url: 'https://downloads.cannerald.com/cannergrow/marketing/logos/',
                logos: {}
            }
        },
        mounted() {
           this.logos = Object.assign({}, this.$i18n.t('marketing.view.logo.logos'));
        },
        methods: {
            toggle(type) {
                this.$set(this.logos[type],'open', !this.logos[type].open);
            },
            getEmbedCode(path, type) {

                let ref_link = process.env.VUE_APP_REFERRAL_SHORT_URL + this.$parent.$parent.ref_links.website.code;

                if (type === 'html') {
                    return '<a href="' + ref_link + '" target="_blank"><img src="' + path + '"></a>'
                } else if (type === 'bbcode') {
                    return '[url=' + ref_link + '][img]' + path + '[/img][/url]';
                }

            },
            copy(toCopy) {
                this.$copyText(toCopy);

                this.$notify({
                    group: 'main',
                    title: 'Copied to clipboard!',
                    type: 'success',
                    data: {
                        class: 'far fa-clipboard',
                    }
                });
            },
            getPath(name, extension) {
                return this.download_url + name + '.' + extension;
            }
        },
    }
</script>